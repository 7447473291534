import React, { useEffect, useState } from "react";
import { Card, Row, Col, Spin, Modal, Table } from "antd";
import {
  IoCheckmarkDoneCircle,
  IoToday,
  IoPeopleOutline,
} from "react-icons/io5";
import { ImDownload2 } from "react-icons/im";
import "./AdminView.css";
import { IoTime } from "react-icons/io5";
import { FaBorderAll } from "react-icons/fa";
import axios from "axios";
import dayjs from "dayjs";
import { getToken } from "../../userRole";
import { staticApis } from "../../api";

const AdminView = () => {
  const [dashBoardData, setDashBoardData] = useState({
    activeRooms: 0,
    totalRooms: 0,
    todaysMeetings: [],
    ongoingMeetings: [],
    totalUsers: 0,
    roomsUnderMaintanence: 0,
  });

  const [loading, setLoading] = useState(true);
  const [isOngoingModalVisible, setIsOngoingModalVisible] = useState(false);
  const [isTodayModalVisible, setIsTodayModalVisible] = useState(false);

  useEffect(() => {
    (async () => {
      const todayDate = dayjs().format("YYYY-MM-DD");
      const currentTime = dayjs().format("HH:mm:ss");
      const params = {
        date: todayDate,
        time: currentTime,
      };
      setLoading(true);
      try {
        const resp = await staticApis.getAdminDashboardData(params);
        console.log("admin dashboard data", resp?.data);
        setDashBoardData(resp?.data);
      } catch (err) {
        console.error("Error fetching dashboard data:", err);
      }
      setLoading(false);
    })();
  }, []);

  const showOngoingModal = () => {
    setIsOngoingModalVisible(true);
  };

  const showTodayModal = () => {
    setIsTodayModalVisible(true);
  };

  const handleOngoingOk = () => {
    setIsOngoingModalVisible(false);
  };

  const handleTodayOk = () => {
    setIsTodayModalVisible(false);
  };

  const handleCancel = () => {
    setIsOngoingModalVisible(false);
    setIsTodayModalVisible(false);
  };

  if (loading) {
    return (
      <div className="loading-container">
        <Spin size="large" />
        <span style={{ marginLeft: "10px" }}>Fetching...</span>
      </div>
    );
  }

  return (
    <div className="booking-status-container">
      <Row gutter={[10, 32]} justify="space-between">
        <Col xs={24} sm={12} md={8} lg={6}>
          <Card bordered className="status-box half-box">
            <IoCheckmarkDoneCircle className="icon" />
            <span className="count">{dashBoardData.activeRooms}</span>
            <div>Active Rooms</div>
          </Card>
          <Card bordered className="status-box half-box">
            <ImDownload2 className="icon" />
            <span className="count">{dashBoardData.roomsUnderMaintanence}</span>
            <div>Rooms Under Maintenance</div>
          </Card>
        </Col>

        <Col xs={24} sm={12} md={8} lg={12}>
          <Row gutter={[10, 32]}>
            <Col span={12}>
              <Card bordered className="status-box full-box">
                <FaBorderAll className="icon-mid" />
                <div className="count-mid">{dashBoardData.totalRooms}</div>
                <div>Total Conference Rooms</div>
              </Card>
            </Col>
            <Col span={12}>
              <Card bordered className="status-box full-box">
                <IoPeopleOutline className="icon-mid" />
                <div className="count-mid">{dashBoardData.totalUsers}</div>
                <div>Number Of Users</div>
              </Card>
            </Col>
          </Row>
        </Col>

        <Col xs={24} sm={12} md={8} lg={6}>
          <Card
            bordered
            className="status-box half-box"
            onClick={
              dashBoardData.ongoingMeetings &&
              dashBoardData.ongoingMeetings.length > 0
                ? showOngoingModal
                : null
            }
          >
            <IoTime className="icon" />
            <span className="count">
              {dashBoardData.ongoingMeetings.length}
            </span>
            <div>Ongoing Bookings</div>
          </Card>
          <Card
            bordered
            className="status-box half-box"
            onClick={
              dashBoardData.todaysMeetings &&
              dashBoardData.todaysMeetings.length > 0
                ? showTodayModal
                : null
            }
          >
            <IoToday className="icon" />
            <span className="count">{dashBoardData.todaysMeetings.length}</span>
            <div>Today's Bookings</div>
          </Card>
        </Col>
      </Row>
      <Modal
        title="Ongoing Booking Details"
        visible={isOngoingModalVisible}
        onOk={handleOngoingOk}
        width={"700px"}
        onCancel={handleCancel}
        footer={null}
        bodyStyle={{ padding: 0 }}
        style={{ top: 60 }}
      >
        <Table
          columns={[
            {
              title: "Room Name",
              dataIndex: "name",
              key: "name",
            },
            {
              title: "Meeting Time",
              dataIndex: "timings",
              key: "timings",
              sorter: (a, b) =>
                new Date(`1970-01-01T${a.startTime}`) -
                new Date(`1970-01-01T${b.startTime}`),
            },
            {
              title: "Meeting Subject",
              dataIndex: "subject",
              key: "subject",
            },
            {
              title: "Created By",
              dataIndex: "createdBy",
              key: "createdBy",
            },
          ]}
          dataSource={dashBoardData.ongoingMeetings.map((meeting, index) => {
            const startTime = new Date(
              `1970-01-01T${meeting.startTime}`
            ).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            });
            const endTime = new Date(
              `1970-01-01T${meeting.endTime}`
            ).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            });

            return {
              key: index,
              name: meeting.name,
              timings: `${startTime} - ${endTime}`,
              subject: meeting.subject,
              createdBy: meeting.createdBy,
              startTime: meeting.startTime,
            };
          })}
          pagination={false}
          scroll={{ y: 150 }}
        />
      </Modal>
      <Modal
        title="Today's Booking Details"
        visible={isTodayModalVisible}
        onOk={handleTodayOk}
        width={"700px"}
        onCancel={handleCancel}
        footer={null}
        bodyStyle={{ padding: 0 }}
        style={{ top: 60 }}
      >
        <Table
          columns={[
            {
              title: "Room Name",
              dataIndex: "name",
              key: "name",
            },
            {
              title: "Meeting Time",
              dataIndex: "timings",
              key: "timings",
              sorter: (a, b) =>
                new Date(`1970-01-01T${a.startTime}`) -
                new Date(`1970-01-01T${b.startTime}`),
            },
            {
              title: "Meeting Subject",
              dataIndex: "subject",
              key: "subject",
            },
            {
              title: "Created By",
              dataIndex: "createdBy",
              key: "createdBy",
            },
          ]}
          dataSource={dashBoardData.todaysMeetings.map((meeting, index) => {
            const startTime = new Date(
              `1970-01-01T${meeting.startTime}`
            ).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            });
            const endTime = new Date(
              `1970-01-01T${meeting.endTime}`
            ).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            });

            return {
              key: index,
              name: meeting.name,
              timings: `${startTime} - ${endTime}`,
              subject: meeting.subject,
              createdBy: meeting.createdBy,
              startTime: meeting.startTime,
            };
          })}
          pagination={false}
          scroll={{ y: 150 }}
        />
      </Modal>
    </div>
  );
};

export default AdminView;
