import React from "react";
import "./App.css";
import { Toaster } from "react-hot-toast";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import {
  PlusOutlined,
  CalendarOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Layout, Menu, theme, Divider, Flex } from "antd";
import MeetingRoom from "./components/projectPages/MeetingRoom";
import Cr from "./components/projectPages/Calendar";
import User from "./components/headerItems/User";
import Rb from "./components/projectPages/Rb";
import { GetRole } from "./userRole";
import AdminView from "./components/projectPages/AdminDashboard";
import MeetingCard from "./components/projectPages/UpNextMeetings";
import { SiGotomeeting } from "react-icons/si";
import { MdOutlineDashboard } from "react-icons/md";

const { Header, Sider } = Layout;
const { SubMenu } = Menu;
const role = GetRole();

const navMenuItems = [
  {
    key: "/",
    icon: <CalendarOutlined />,
    label: <Link to="/">Meeting calendar</Link>,
  },
  {
    key: "/upNext-meetings",
    icon: <SiGotomeeting />,
    label: <Link to="/upNext-meetings">Upcoming meetings</Link>,
  },
  {
    key: "/meeting-room-booking",
    icon: <CalendarOutlined />,
    label: <Link to="/meeting-room-booking">Book new meeting</Link>,
  },
  role === "admin" && {
    key: "/meeting-room",
    icon: <PlusOutlined />,
    label: <Link to="/meeting-room">Add meeting room</Link>,
  },
  role === "admin" && 
      {
        key: "/admin-view",
        icon: <MdOutlineDashboard />,
        label: <Link to="/admin-view">Admin dashboard</Link>,
      }
  // role === "admin" && {
  //   key: "AdminView",
  //   icon: <UserOutlined />,
  //   label: "Admin View",
  //   children: [
  //     {
  //       key: "/admin-view",
  //       icon: <MdOutlineDashboard />,
  //       label: <Link to="/admin-view">Dashboard</Link>,
  //     },
  //   ],
  // }

].filter(Boolean);

function App() {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const location = useLocation();

  return (
    <Layout>
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Flex>
          <img
            src="https://spanidea.com/in/wp-content/uploads/2022/08/png-white-logo-300x53.png"
            alt="logo"
            style={{ height: "30px" }}
          />
        </Flex>
        <Flex justify="center">
          <span
            style={{
              color: "white",
              fontSize: "25px",
              textAlign: "center",
              fontWeight: "600",
              marginRight: 40,
            }}
          >
            Conference Room Booking
          </span>
        </Flex>
        <Flex style={{ paddingRight: "10px" }}>
          <User />
        </Flex>
      </Header>
      <Layout>
        <Sider
          width={200}
          style={{
            background: colorBgContainer,
          }}
        >
          <Menu
            mode="inline"
            defaultSelectedKeys={["/"]}
            selectedKeys={[location.pathname]}
            items={navMenuItems}
            style={{
              height: "100%",
              borderRight: 0,
              marginTop: "1rem",
            }}
          >
            {navMenuItems.map((menuItem, index) => {
              if (menuItem.children) {
                return (
                  <SubMenu
                    key={menuItem.key}
                    icon={menuItem.icon}
                    title={menuItem.label}
                  >
                    {menuItem.children.map((subItem) => (
                      <Menu.Item key={subItem.key} icon={subItem.icon}>
                        {subItem.label}
                      </Menu.Item>
                    ))}
                  </SubMenu>
                );
              }
              return (
                <>
                  <Menu.Item key={menuItem.path} icon={menuItem.icon}>
                    {menuItem.label}
                  </Menu.Item>
                  {index < navMenuItems.length - 1 && (
                    <Divider style={{ margin: 0 }} />
                  )}
                </>
              );
            })}
          </Menu>
        </Sider>
        <Layout className="contentWrapper">
          <div
            style={{
              padding: 24,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            <Routes>
              <Route path="/" element={<Cr />} />
              {role === "admin" && (
                <Route path="/meeting-room" element={<MeetingRoom />} />
              )}
              {/* <Route path="/meetings" element={<Cr />} /> */}
              <Route path="/meeting-room-booking" element={<Rb />} />
              <Route path="/admin-view" element={<AdminView />} />
              <Route path="/upNext-meetings" element={<MeetingCard />} />
            </Routes>
          </div>
        </Layout>
      </Layout>
    </Layout>
  );
}

function AppWrapper() {
  return (
    <BrowserRouter>
      <App />
      <Toaster />
    </BrowserRouter>
  );
}

export default AppWrapper;
