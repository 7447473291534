import axios from "axios";
import Cookies from "js-cookie";

const getToken = Cookies.get("token");

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE}/`,
  headers: {
    // 'ngrok-skip-browser-warning': true,
    Authorization: `Bearer ${getToken && JSON.parse(getToken.split('j:')[1]).token}`,
  },
  // timeout: 15000,
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      const status = error.response.status;
      if (status === 401) {
      }
    }
    return Promise.reject(error);
  }
);

const responseBody = (response) => response.data;

const requests = {
  get: (url,reqParams) => instance.get(url,{params:reqParams}).then(responseBody),

  post: (url, body) =>
    instance
      .post(url, body, { headers: { "content-type": "application/json" } })
      .then(responseBody),

  put: (url, body) => instance.put(url, body, {}).then(responseBody),
  patch: (url, body) => instance.patch(url, body,{}).then(responseBody),
  delete: (url, body,reqParams) => instance.delete(url, {data:body,params:reqParams}).then(responseBody),
};

export const clientsApi = {
  getCityList: (data) => requests.get("/api/static/get-city", data),
  getEmployeeName: (data) => requests.get("api/employee/get/all", data),
};

export const inviteesApi = {
  checkSlotAvailability: (data) =>
    requests.post(`api/invitees/check-slot-availability`, data),
  getInvitees: (data) =>
    requests.get(`api/invitees/get-invitees-list/${data.rtId}/${data.empCode}`),
  updateInvitees: (data,params) =>
    requests.delete(
      `api/invitees/delete-invitees/${params.id}/${params.empCode}`,
      data
    ),
};

export const contracts = {
  clientNameList: (data) => requests.get("/api/client/get/list", data),
  contractsAllData: (file) => requests.get(`api/contract/get/all?name=${file}`),
  getContractsData: (clientId) => requests.get(`api/contract/get/${clientId}`),
  addContractsData: (data) => requests.post("api/contract/create", data),
  updateContractsData: (clientId, data) =>
    requests.put(`api/contract/update/${clientId}`, data),
};

export const roomBookingApi = {
  bookRoom: (data) => requests.post("api/room-booking/create", data),
  getMeetingListData: (data) =>
    requests.post("api/room-booking/get-meeting-list", data),
  updateRoomTime: (data,params) =>
    requests.patch(
      `api/room-booking/update-room-booking/${params.roomBookingId}/${params.empCode}`,data
    ),
    deleteRoomTime:(data)=>requests.delete(`api/room-booking/delte-room-time/${data.rtId}/${data.empCode}`),
    deleteRoomBooking:(data)=>requests.delete(`api/room-booking/delte-room-booking/${data.rbId}/${data.empCode}`),
    getUpcomingMeetings:(params)=>requests.get("/api/room-booking/upcoming-meetings",params)
};

// export const projectApi = {
//   getAllProjectDetails: (page) =>
//     requests.get(`api/project/get/all?page=${page}`),
//   getProjectDetails: (projectId) => requests.get(`api/client/get/${projectId}`),
//   getEmployeeName: (data) => requests.get("api/employee/get", data),
//   addProjectDetails: (data) => requests.post("api/project/create", data),
//   updateProjectDetails: (projectId, data) =>
//     requests.put(`api/client/update/${projectId}`, data),
// };

export const staticApis = {
  getConcurrencyTypes: () => requests.get("/api/static/get-concurrency-type"),
  getEmployeesData:(employeeNo)=>requests.get(`/api/static/get-employees/${employeeNo}`),
  getAdminDashboardData:(params)=>requests.get("/api/static/admin-dashboard",params)
};

export const meetingRoomApis = {
  meetingRoomAllData: (data) => requests.get("api/meeting-room/list", data),
  createMeetingRoom: (data) => requests.post("/api/meeting-room/create", data),
  deleteMeetingRoom: (clientId,params) =>
    requests.delete(`/api/meeting-room/delete/${clientId}`,null,params),
  updateMeetingRoom: (clientId, data) =>
    requests.patch(`api/meeting-room/update/${clientId}`, data),
  getMeetingRoomData: (clientId) =>
    requests.get(`/api/meeting-room/get/${clientId}`),
  getAvailabilityOfMeetingRoom: (data) =>
    requests.get(
      `api/meeting-room/get-availability-of-meeting-room?mrId=${data.mrId}&concurrencyStartDate=${data.concurrencyStartDate}&concurrencyEndDate=${data.concurrencyEndDate}&startTime=${data.startTime}&endTime=${data.endTime}${data.rtId ? `&rtId=${data.rtId}` : ""}`
    ),
    getMeetingsOfMeetingRoom:(params)=>requests.get("api/meeting-room/meetings-of-meeting-room",params)
};
