import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Button, Flex, Input, Select, DatePicker, Form, Modal, Tag, Spin } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import {
  meetingRoomApis,
  roomBookingApi,
  staticApis,
  clientsApi,
  inviteesApi,
} from "../../api";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import SlotBookingPopup from "./SlotBookingPopup";
import { WeeklyCalendar } from "./WeeklyCalendar";
import {
  setSelectedMeetingRoom,
  setSelectedCity,
} from "../../reducer/meetingSlice";
import { employeeId } from "../../userRole";


const Rb = () => {
  const [concurrenceOptions, setConcurrenceOptions] = useState([]);
  const [meetingRooms, setMeetingRooms] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dateChanged, setDateChanged] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(null);
  const [formData, setFormData] = useState({});
  const [concurrenceType, setConcurrenceType] = useState(1);
  const [form] = Form.useForm();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { TextArea } = Input;
  const { TimePicker } = DatePicker;
  const [roomEvents, setRoomEvents] = useState([]);
  const [agenda, setAgenda] = useState("");
  const [cityList, setCityList] = useState([]);
  const selectedMeetingRoom = useSelector(
    (state) => state.meetingRoom.selectedMeetingRoom
  );
  const selectedCity = useSelector((state) => state.meetingRoom.selectedCity);
  const dispatch = useDispatch();

  const [myEvents, setEvents] = useState([]);
  const [dateRange, setDateRange] = useState({
    start: moment().startOf("week").toDate(),
    end: moment().endOf("week").toDate(),
  });
  const [selectedSlot, setSelectedSlot] = useState(null);
  const selectedDate = state?.selectedDate;
  const [startDate, setStartDate] = useState(
    selectedDate ? dayjs(selectedDate) : dayjs()
  );

  const [endDate, setEndDate] = useState(dayjs());
  const [startTime, setStartTime] = useState(dayjs().add(0, "minute"));
  const [endTime, setEndTime] = useState(dayjs().add(30, "minute"));

  const [checkSlot, setCheckSlot] = useState([]);
  const [selectedInvitees, setSelectedInvitees] = useState([]);
  const [loadingInvitees, setLoadingInvitees] = useState([]);

  const handleMeetingRoomChange = (value) => {
    dispatch(setSelectedMeetingRoom(value));
  };

  
  const calculateWeekdays = (startDate, endDate) => {
    let count = 0;
    let currentDate = dayjs(startDate);
  
    while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, 'day')) {
      const dayOfWeek = currentDate.day(); // 0 for Sunday, 6 for Saturday
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        count++;
      }
      currentDate = currentDate.add(1, 'day'); // Move to the next day
    }
  
    return count;
  };


  const showModal = (data) => {
    setConfirmationModal(data);
  };

  const handlePopupCancel = () => {
    setConfirmationModal(null);
  };

  const handleCityChange = (value) => {
    dispatch(setSelectedCity(value));
  };

  const handleSelectSlot = (slotInfo) => {
    const start = dayjs(slotInfo.start);
    const end = dayjs(slotInfo.end);

    if (!start.isValid() || !end.isValid() || start < dayjs()) {
      console.error("Invalid start or end date");
      return;
    }

    setStartDate(start);
    // setEndDate(end);
    setStartTime(start);
    setEndTime(end);
    setSelectedSlot(slotInfo);

    console.log("start", start);
    console.log("end", end);
    form.setFieldsValue({
      concurrencyStartDate: start,
      concurrencyEndDate: end,
      startTime: start,
      endTime: end,
    });
    form.validateFields([
      "concurrencyStartDate",
      "concurrencyEndDate",
      "startTime",
      "endTime",
    ]);
  };

  const handleInputChange = (field, value) => {
    switch (field) {
      case "startDate":
        setStartDate(value);
        setDateChanged(
          dayjs(value).startOf("day").isSame(dayjs().startOf("day"))
        );
        form.setFieldsValue({ concurrencyStartDate: value });
        validateStartDate(null, value)
          .then(() => {
            form.setFields([{ name: "concurrencyStartDate", errors: [] }]);
          })
          .catch((error) => {
            form.setFields([{ name: "concurrencyStartDate", errors: [error] }]);
          });
        break;

      case "endDate":
        setEndDate(value);
        form.setFieldsValue({ concurrencyEndDate: value });

        if (startDate && dayjs(startDate).isAfter(value)) {
          form.setFields([
            {
              name: "concurrencyStartDate",
              errors: ["Start date must be less than end date"],
            },
          ]);
        } else {
          form.setFields([{ name: "concurrencyStartDate", errors: [] }]);
        }
        break;

      case "startTime":
        setStartTime(value);
        form.setFieldsValue({ startTime: value });
        break;
      case "endTime":
        setEndTime(value);
        setDateChanged(
          dayjs(value).startOf("day").isSame(dayjs().startOf("day"))
        );
        form.setFieldsValue({ endTime: value });
        form.validateFields(["startTime"]);
        break;
      default:
        break;
    }
    form.validateFields([field]);
  };

  const filteredMeetingRooms = selectedCity
    ? meetingRooms.filter((room) =>
        cityList.some(
          (city) => city.label === room.place && city.value === selectedCity
        )
      )
    : [];

  useEffect(() => {
    const fetchAvailableRoomData = async () => {
      try {
        const formattedStartDate = dayjs(dateRange.start).format("YYYY-MM-DD");
        const formattedEndDate = dayjs(dateRange.end).format("YYYY-MM-DD");
        const payload = {
          meetingRoomId: selectedMeetingRoom,
          startDate: formattedStartDate,
        };
        payload.endDate = formattedEndDate;
        const response = await meetingRoomApis.getMeetingsOfMeetingRoom(
          payload
        );

        console.log("Meeting room availability data:", response?.data);
        const meetingsData = response?.data?.map((meeting) => ({
          start: moment(meeting.date + "T" + meeting.startTime).toDate(),
          end: moment(meeting.date + "T" + meeting.endTime).toDate(),
        }));

        setRoomEvents(meetingsData);
      } catch (err) {
        setRoomEvents([]);
        console.error("Error fetching availability data:", err);
      }
    };

    fetchAvailableRoomData();
  }, [dateRange.start, dateRange.end, selectedMeetingRoom]);

  // useEffect(() => {
  //   const fetchMeetingData = async () => {
  //     try {
  //       const response = await roomBookingApi.getMeetingListData({
  //         empCode: employeeId,
  //         startDate: dateRange.start,
  //         endDate: dateRange.end,
  //       });

  //       const meetingsData = response.data.map((meeting) => ({
  //         id: meeting.id,
  //         start: moment(meeting.date + "T" + meeting.startTime).toDate(),
  //         end: moment(meeting.date + "T" + meeting.endTime).toDate(),
  //         title: meeting.subject,
  //         rtId: meeting.rtId,
  //         createdBy: meeting.createdBy,
  //         empCode: meeting.empCode,
  //         date: meeting.date,
  //         mrId: meeting.mrId,
  //         agenda: meeting.agenda,
  //         meetingRoomName: meeting.name,
  //         rbId: meeting.rbId,
  //         meetingRoomLink: meeting.meetingRoomLink,
  //       }));

  //       setEvents(meetingsData);
  //     } catch (error) {
  //       console.error("Error fetching meeting data:", error);
  //       setEvents([]);
  //     }
  //   };

  //   fetchMeetingData();
  // }, [dateRange.start && dateRange.end]);

  useEffect(() => {
    staticApis.getConcurrencyTypes().then((res) => {
      const optionsData = res.data
        .filter((data) => data.id !== 3 && data.id !== 4 && data.id !== 2)
        .map((concurrency) => {
          return {
            value: concurrency.id,
            label: concurrency.name,
          };
        });
      setConcurrenceOptions([...optionsData]);
    });
  }, []);

  useEffect(() => {
    clientsApi.getCityList().then((res) => {
      const cityList = res?.data?.map((mCity) => {
        return {
          value: mCity.id,
          label: mCity.name,
        };
      });
      setCityList([...cityList]);
    });
  }, []);

  useEffect(() => {
    meetingRoomApis.meetingRoomAllData().then((res) => {
      const meetingRooms = res?.data?.map((mRoom) => {
        return {
          value: mRoom.mrId,
          label: mRoom.name,
          place: mRoom.place,
        };
      });
      setMeetingRooms([...meetingRooms]);
    });
  }, []);

  useEffect(() => {
    const getEmployees = async () => {
      setLoading(true);
      try {
        const resp = await staticApis.getEmployeesData(employeeId);
        const employeesData = resp?.data?.map((employee) => {
          return {
            label: employee.name + " ( " + employee.employeeNo + " ) ",
            value: employee.employeeNo,
          };
        });
        setEmployees([...employeesData]);
      } catch (err) {
        console.log("ERROR IN GET EMPLOYEES DATA API ", err);
      }
      setLoading(false);
    };
    getEmployees();
  }, []);

  const fetchAvailability = async (invitees) => {
    setLoadingInvitees(invitees); // Set loading for selected invitees
    try {
      const resp = await inviteesApi.checkSlotAvailability({
        empCodes: invitees,
        date: dayjs(startDate).startOf("day").format("YYYY-MM-DD"),
        startTime: dayjs(startTime).startOf("minute").format("HH:mm"),
        endTime: dayjs(endTime).startOf("minute").format("HH:mm"),
      });
      setCheckSlot(resp.data);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoadingInvitees([]);
    }
  };

  useEffect(() => {
    if (selectedInvitees.length > 0) {
      fetchAvailability(selectedInvitees);
    }
  }, [selectedInvitees, startDate, startTime, endTime]);

  const handleInviteesChange = (value) => {
    setSelectedInvitees(value);
  };

  const getAvailability = (empCode) => {
    if (!checkSlot || !Array.isArray(checkSlot)) return null;
    const availabilityInfo = checkSlot.find((slot) => slot.empCode === empCode);
    return availabilityInfo ? availabilityInfo.availability : null;
  };

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const isLoading = loadingInvitees.includes(value); // Check if the invitee is loading
    const availability = checkSlot.find((slot) => slot.empCode === value)?.availability;
    const color = availability ? "green" : "red";
  
    return (
      <Tag
        color={isLoading ? "blue" : color}
        closable={closable}
        onClose={onClose}
        style={{ display: "flex", alignItems: "center" }}
      >
        {label}
        {isLoading && <Spin size="small" style={{ marginLeft: 8 }} />}
      </Tag>
    );
  };

  useEffect(() => {
    form.resetFields(["mrId"]);
  }, [selectedCity]);

  const validateStartDate = (_, value) => {
    const formattedValue = value.format("YYYY-MM-DD");
    if (concurrenceType === 1) {
      return Promise.resolve();
    }
    const endingDate = endDate.format("YYYY-MM-DD");
    if (value && endingDate && endingDate <= formattedValue) {
      return Promise.reject("Start must be less than end date");
    }
    return Promise.resolve();
  };

  const validateStartTime = (_, value) => {
    const endTime = form.getFieldValue("endTime").format("HH:mm");
    const formattedValue = value.format("HH:mm");

    if (formattedValue >= endTime) {
      return Promise.reject();
    }
    return Promise.resolve();
  };

  const bookRoomAfterCheck = async (values) => {
    const toastId = toast.loading("Loading...");
    try {
      const {
        concurrencyStartDate,
        concurrencyEndDate,
        startTime,
        endTime,
        concurrenceTypeId,
        roomInvitees,
        ...rest
      } = values;

      const updatedValues = {
        ...rest,
        concurrencyStartDate: concurrencyStartDate.format("YYYY-MM-DD"),
        ...(concurrenceType === 1
          ? {
              concurrence: [concurrencyStartDate.format("YYYY-MM-DD")],
              concurrencyEndDate: concurrencyStartDate.format("YYYY-MM-DD"),
            }
          : { concurrencyEndDate: concurrencyEndDate.format("YYYY-MM-DD") }),
        startTime: startTime.format("HH:mm"),
        endTime: endTime.format("HH:mm"),
        concurrenceTypeId: concurrenceType,
        createdBy: employeeId,
        roomInvitees: roomInvitees ? [...roomInvitees, employeeId]:[employeeId],
      };

      
      const totalSlots = calculateWeekdays(concurrencyStartDate, concurrencyEndDate);
      console.log("Printing total slots (excluding weekends):", totalSlots);


      const resp = await meetingRoomApis.getAvailabilityOfMeetingRoom({
        mrId: updatedValues.mrId,
        concurrencyStartDate: updatedValues.concurrencyStartDate,
        concurrencyEndDate: updatedValues.concurrencyEndDate,
        startTime: updatedValues.startTime,
        endTime: updatedValues.endTime,
      });


      console.log("printing get availability of meeting room response ",resp);

      let unavailableDates =
        updatedValues.concurrencyStartDate !== updatedValues.concurrencyEndDate
          ? resp?.data?.unavailableDates?.map((date) =>
              moment(date).toDate().toDateString()
            )
          : [];

      const slotAvailabilityPercentage =
        concurrenceType === 1
          ? (resp?.data?.noOfUnavailableSlots /
              (concurrencyStartDate.toDate().getDate() -
                concurrencyStartDate.toDate().getDate() +
                1)) *
            100
          : (resp?.data?.noOfUnavailableSlots /
              (concurrencyEndDate.toDate().getDate() -
                concurrencyStartDate.toDate().getDate() +
                1)) *
            100;

      if(slotAvailabilityPercentage>25){
          toast.error("Meeting room not available.");
          toast.dismiss(toastId)
          return;
      }
      if (
        resp?.data?.noOfUnavailableSlots > 0 &&
        unavailableDates.length !== 0
      ) {
        const updatedConcurrencyStartDate = new Date(
          resp?.data?.unavailableDates[resp?.data?.unavailableDates?.length - 1]
        );
        updatedConcurrencyStartDate.setDate(
          updatedConcurrencyStartDate.getDate() + 1
        );
        updatedValues.concurrencyStartDate = moment(
          updatedConcurrencyStartDate
        ).format("YYYY-MM-DD");
      }




      const resp2 = await roomBookingApi.bookRoom(updatedValues);
      console.log("PRINTING RESPONSE OF CREATE ROOM BOOKING API ", resp2);
      navigate("/meetings");
      toast.success(slotAvailabilityPercentage===0 ?"Meeting Room booked.":`Meeting Room booked for ${totalSlots-unavailableDates.length} out of ${totalSlots} slots.`);
      setConfirmationModal(null);
  }
     catch (err) {
      console.log("ERROR IN CREATE ROOM BOOKING API ------", err);
    }
    toast.dismiss(toastId);
  };

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      {/* For Admin */}
      <div style={{ width: "580px" }}>
        <Form form={form} onFinish={bookRoomAfterCheck} layout="vertical">
          <Flex gap="1rem" align="center">
            <Form.Item
              label="Place"
              name="placeId"
              rules={[{ required: true, message: "Please select a place" }]}
            > 
              <Select
                showSearch
                size="large"
                id="placeId"
                style={{ width: "7.5vw" }}
                placeholder="Select Place"
                options={cityList}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={handleCityChange}
              />
            </Form.Item>

            <Form.Item
              name="mrId"
              label="Meeting rooms"
              rules={[{ required: true, message: "Meeting room id is required" }]}
            >
              <Select
                showSearch
                size="large"
                id="mrId"
                options={filteredMeetingRooms}
                placeholder="Select meeting room"
                style={{ width: "12vw" }}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={handleMeetingRoomChange}
                disabled={!selectedCity}
              />
            </Form.Item>

            <Form.Item
              label="Subject"
              name={"subject"}
              rules={[{ required: true, message: "Subject is required" }]}
            >
              <Input
                type="text"
                name="subject"
                style={{ width: "15.5vw" }}
                // onChange={handleChange}
                id="subject"
                size="large"
                placeholder="Enter meeting subject"
                maxLength={35}
              />
            </Form.Item>
          </Flex>

          {/* Start Date and Time*/}
          <Flex gap="1.2rem" style={{ width: "100%" }} align="center">
            <Form.Item
              name="concurrencyStartDate"
              label="Start Date"
              initialValue={startDate}
              rules={[
                { required: true },
                {
                  validator: validateStartDate,
                  message: "Start date must be less than end date",
                },
              ]}
            >
              <div>
                <DatePicker
                  size="large"
                  style={{ width: "15.5vw" }}
                  value={startDate}
                  onChange={(date) => handleInputChange("startDate", date)}
                  minDate={dayjs().startOf("day")}
                  allowClear={false}
                />
              </div>
            </Form.Item>

            <Form.Item
              name="startTime"
              label="Start Time"
              rules={[
                {
                  validator: validateStartTime,
                  message: "Start time must be less than end time",
                },
              ]}
              initialValue={startTime}
            >
              <div>
                <TimePicker
                  size="large"
                  needConfirm={false}
                  style={{ width: "100%" }}
                  allowClear={false}
                  format={"HH:mm"}
                  disabledTime={() => {
                    const now = dayjs();
                    const currentHour = now.hour();
                    const currentMinute = now.minute();
                    const currentDate = now.startOf("day");
                    const selectedDate = dayjs(startDate).startOf("day");

                    if (selectedDate.isSame(currentDate)) {
                      return {
                        disabledHours: () => {
                          const hours = [];
                          for (let i = 0; i < currentHour; i++) hours.push(i);
                          return hours;
                        },
                        disabledMinutes: (selectedHour) => {
                          const minutes = [];
                          if (selectedHour === currentHour) {
                            for (let i = 0; i < currentMinute; i++)
                              minutes.push(i);
                          }
                          return minutes;
                        },
                      };
                    }

                    return {
                      disabledHours: () => [],
                      disabledMinutes: () => [],
                    };
                  }}
                  value={startTime}
                  onChange={(time) => handleInputChange("startTime", time)}
                  minuteStep={5}
                />
              </div>
            </Form.Item>

          </Flex>

          {/* End Date and time */}
          
            <Flex
              gap="1.2rem"
              style={{ width: "100%", marginBottom: "0.8rem" }}
              align="center"
            >
              <Form.Item
                name="concurrencyEndDate"
                label="End Date"
                style={{}}
                initialValue={endDate}
              >
                <div>
                  <DatePicker
                    allowClear={false}
                    size="large"
                    style={{ width: "15.5vw" }}
                    value={endDate}
                    disabled={concurrenceType===1}
                    onChange={(date) => handleInputChange("endDate", date)}
                    minDate={dayjs(form.getFieldValue("concurrencyStartDate"))}
                    maxDate={dayjs(
                      form.getFieldValue("concurrencyStartDate")
                    ).add(1, "month")}
                  />
                </div>
              </Form.Item>

              <Form.Item
                name="endTime"
                label="End Time"
                style={{}}
                initialValue={endTime}
              >
                <div>
                  <TimePicker
                    needConfirm={false}
                    allowClear={false}
                    size="large"
                    style={{ width: "100%" }}
                    minuteStep={5}
                    disabledTime={() => {
                      const now = dayjs();
                      const currentHour = now.hour();
                      const currentMinute = now.minute();
                      const currentDate = now.startOf("day");
                      const selectedDate = dayjs(startDate).startOf("day");

                      if (selectedDate.isSame(currentDate)) {
                        return {
                          disabledHours: () => {
                            const hours = [];
                            for (let i = 0; i < currentHour; i++) hours.push(i);
                            return hours;
                          },
                          disabledMinutes: (selectedHour) => {
                            const minutes = [];
                            if (selectedHour === currentHour) {
                              for (let i = 0; i < currentMinute; i++)
                                minutes.push(i);
                            }
                            return minutes;
                          },
                        };
                      }

                      return {
                        disabledHours: () => [],
                        disabledMinutes: () => [],
                      };
                    }}
                    format={"HH:mm"}
                    value={endTime}
                    onChange={(time) => handleInputChange("endTime", time)}
                  />
                </div>
              </Form.Item>
            </Flex>
         

          <Form.Item
            name="roomInvitees"
            label="Invitees"
          >
            <Select
              showSearch
              placeholder="Select invitees"
              allowClear
              optionFilterProp="children"
              options={employees}
              // notFoundContent={loading ? <Spin size="small"/> : null}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              mode="multiple"
              onChange={handleInviteesChange}
              tagRender={tagRender}
            />
          </Form.Item>
          
          <Flex gap="1rem" align="center">
            <Form.Item
              name={"meetingRoomLink"}
              label="Link"
              rules={[
                {
                  type: "url",
                  message: "Please enter a valid URL for the meeting room link",
                },
              ]}
            >
              <Input
                size="large"
                style={{ width: "25vw" }}
                name="meetingRoomLink"
                id="meetingRoomLink"
                placeholder="Enter meeting room link"
              />
            </Form.Item>

              {concurrenceOptions.length > 0 && (
                <Form.Item
                  name="concurrenceTypeId"
                  label="Concurrency"
                  rules={[
                    { required: true, message: "Concurrence type is required" },
                  ]}
                  
                  initialValue={concurrenceOptions[0].value}
                >
                  <Select
                    options={concurrenceOptions}
                    onChange={(val) => {
                      setConcurrenceType(val);
                    }}
                    style={{ width: "11.5vw", height: "40px"}}
                    defaultValue={concurrenceOptions[0].value}
                  />
                </Form.Item>
              )}
          </Flex>

          <Form.Item
            name="agenda"
            label="Agenda"
            rules={[{ required: true, message: "Agenda is required" }]}
          >
            <ReactQuill
              style={{
                borderRadius: "2%",
              }}
              value={agenda}
              onChange={setAgenda}
              theme="snow"
              modules={{
                toolbar: [
                  [{ font: [] }],
                  [{ size: [] }],
                  ["bold", "italic", "underline", "strike"],
                  [
                    { list: "ordered" },
                    { list: "bullet" },
                    // {'indent': '-1'}, {'indent': '+1'}],
                  ],
                  // ['link', 'image', 'video'],
                  // ['clean']
                ],
              }}
            />
          </Form.Item>

          <Flex>
            <Button
              type="primary"
              htmlType="submit"
              style={{ border: "none" }}
            >
              Book meeting room
            </Button>
          </Flex>
        </Form>
      </div>
      
      <div style={{ marginLeft: "0.5rem", flex: 1 }}>
        <WeeklyCalendar
          selectedMeetingRoom={selectedMeetingRoom}
          selectedPlace={selectedCity}
          events={roomEvents}
          selectSlot={handleSelectSlot}
          startDate={startDate}
          endDate={endDate}
          startTime={startTime}
          endTime={endTime}
          selectedSlot={selectedSlot}
          dateRange={dateRange}
          setDateRange={setDateRange}
        />
      </div>

    </div>
  );
};

export default Rb;
